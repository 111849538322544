.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 10;
  background: rgba(0, 0, 0, 0.4);
  cursor: auto;
}

.modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 1000px;
  height: 570px;
  z-index: 10;
  overflow: hidden;
  background-color: white;
  margin: auto;
  animation: appear 0.2s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
  border-radius: 5px;
}

.modal::-webkit-scrollbar {
  width: 3px;
}

.modal::-webkit-scrollbar-track {
  background: #ecedf2;
  border-radius: 16px;
  margin: 0.5rem;
}

.modal::-webkit-scrollbar-thumb {
  background: rgba(153, 153, 153, 0.5);
  border-radius: 16px;
}

.modal::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.modal > div {
  width: 100%;
  height: 100%;
  text-align: left;
}

.footer {
  text-align: right;
  margin-right: 3rem;
  margin-bottom: 1rem;
}

@media (min-width: 768px) and (max-width: 1023px) {
  .modal {
    width: 80%;
    height: 60vh;
  }
}

@media (max-width: 767px) {
  .modal {
    width: 80%;
    height: 60vh;
  }
}

@font-face {
  font-family: "Gilroy";
  src: url("../../Fonts/Gilroy Font/Gilroy-Bold.woff");
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: "Gilroy";
  src: url("../../Fonts/Gilroy Font/Gilroy-Regular.woff");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "Gilroy";
  src: url("../../Fonts/Gilroy Font/Gilroy-SemiBold.woff");
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: "Gilroy";
  src: url("../../Fonts/Gilroy Font/Gilroy-Medium.woff");
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: "Avenir";
  src: url("../../Fonts/avenir_ff/AvenirLTStd-Black.otf");
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: "Metropolis";
  src: url("../../Fonts/Metropolis/Metropolis-SemiBold.otf");
  font-style: normal;
  font-weight: 600;
}

.container {
  width: 90%;
  margin: 8rem auto;
  box-sizing: border-box;
}

.header {
  text-align: center;
  margin-bottom: 4rem;
}

.header > h4 {
  font-family: Gilroy;
  font-weight: 600;
  font-size: 36px;
  line-height: 40px;
  color: #1a1a1a;
}

.header > p {
  font-family: Gilroy;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 180%;
  text-align: center;
  color: #303030;
  width: 560px;
  margin: auto;
}

.testimonial {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 90vh;
}

.testimonial > div:nth-child(1) {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
}

.testimonial > div:nth-child(1) > div {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    linear-gradient(313.78deg, #2855ae -3.15%, #7292cf 100%);
  border-radius: 31.7846px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 469px;
  width: 507.8px;
}

.testimonial > div:nth-child(1) > div > img {
  /* height: 426.33px;
  width: 461.23px; */
  height: calc(100% - 46px);
  width: calc(100% - 46px);
  border-radius: 31.7846px;
  object-fit: cover;
}

.testimonial > div:nth-child(2) {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 48%;
  background: #f2f3f8;
  border-radius: 30px;
  padding: 5rem 5%;

  /* height: 80%; */
}
.testimonial > div:nth-child(2) > p:nth-child(1) {
  font-family: Gilroy;
  font-weight: 500;
  font-size: 28px;
  line-height: 54px;
  color: #303030;
}

.testimonial > div:nth-child(2) > p:nth-child(2) {
  font-family: Avenir;
  font-weight: 800;
  font-size: 18px;
  line-height: 38px;
  color: #303030;
}

.testimonial > div:nth-child(2) > p:nth-child(2) > span {
  font-family: Gilroy;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 1.3px;
  color: #77869e;
}

.testimonial > div:nth-child(2) > div:nth-child(3) {
  font-family: Gilroy;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #2855ae;
  display: inline-flex;
  align-items: center;
  gap: 8px;
  margin-top: 1rem;
  cursor: pointer;
}

@media (min-width: 767px) and (max-width: 1023px) {
  .container {
    width: 90%;
    margin: 5rem auto;
    box-sizing: border-box;
  }

  .header > h4 {
    font-size: 30px;
    line-height: 40px;
    margin: 0.9rem 0;
  }

  .header > p {
    width: auto;
    font-size: 16px;
    line-height: 180%;
  }

  .testimonial {
    flex-direction: column;
    width: 100%;
    height: auto;
  }

  .subTestimonial {
    width: 100%;
  }
  /* .testimonial > div:nth-child(1),
  div:nth-child(2) {
    width: 100%;
  } */

  .testimonial > div:nth-child(1) {
    order: 1;
    margin-top: 2rem;
  }

  .testimonial > div:nth-child(1) > div {
    height: 319px;
    width: 457.8px;
  }

  .testimonial > div:nth-child(1) > div > img {
    height: calc(100% - 23px);
    width: calc(100% - 6px);
    border-radius: 24.35px;
  }

  .testimonial > div:nth-child(2) {
    width: 100%;
    margin-top: 2rem;
    padding: 2rem 1rem;
  }

  .testimonial > div:nth-child(2) > p:nth-child(1) {
    font-size: 20.5939px;
    line-height: 40px;
  }
}

@media (max-width: 767px) {
  .container {
    width: 90%;
    margin: 5rem auto;
    box-sizing: border-box;
  }

  .header > h4 {
    font-size: 30px;
    line-height: 40px;
    margin: 0.9rem 0;
  }

  .header > p {
    width: auto;
    font-size: 16px;
    line-height: 180%;
  }

  .testimonial {
    flex-direction: column;
    width: 100%;
    height: auto;
  }

  /* .testimonial > div:nth-child(1),
  div:nth-child(2) {
    width: 100%;
  } */

  .testimonial > div:nth-child(1) {
    order: 1;
    margin-top: 2rem;
  }

  .testimonial > div:nth-child(1) > div {
    height: 319px;
    width: 457.8px;
  }

  .testimonial > div:nth-child(1) > div > img {
    height: calc(100% - 23px);
    width: calc(100% - 6px);
    border-radius: 24.35px;
  }

  .testimonial > div:nth-child(2) {
    width: 100%;
    margin-top: 2rem;
    padding: 2rem 1rem;
  }

  .testimonial > div:nth-child(2) > p:nth-child(1) {
    font-size: 20.5939px;
    line-height: 40px;
  }
}

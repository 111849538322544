@font-face {
  font-family: "Gilroy";
  src: url("../../Fonts/Gilroy Font/Gilroy-Bold.woff");
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: "Gilroy";
  src: url("../../Fonts/Gilroy Font/Gilroy-Regular.woff");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "Gilroy";
  src: url("../../Fonts/Gilroy Font/Gilroy-SemiBold.woff");
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: "Gilroy";
  src: url("../../Fonts/Gilroy Font/Gilroy-Medium.woff");
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: "Metropolis";
  src: url("../../Fonts/Metropolis/Metropolis-SemiBold.otf");
  font-style: normal;
  font-weight: 600;
}

.header {
  text-align: center;
  background: #f2f3f8;
  height: 80vh;
}

.header > div > h4 {
  font-family: Gilroy;
  font-weight: 700;
  font-size: 54px;
  line-height: 74px;
  color: #1a1a1a;
  margin: 0;
  padding: 3em 0 1em 0;
}

.header > div > p {
  font-family: Gilroy;
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 40px;
  text-align: center;
  color: #303030;
  width: 906px;
  margin: auto;
}

.body {
  background: #ffffff;
  background: #ffffff;
  box-shadow: 2.30081px 11.5041px 23.0081px 1.15041px rgba(221, 227, 255, 0.45);
  border-radius: 20px;
  width: 80%;
  margin: 0 auto 5rem auto;
  padding: 5.6rem 0;
  position: relative;
  top: -100px;
}

.bodyInner {
  width: 76%;
  margin: auto;
  position: relative;
  top: 0;
}

.bodyInner > div:nth-child(1) {
  margin-bottom: 4rem;
}

.bodyInner > div:nth-child(1) > img {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.bodyInner > div:nth-child(2) > p {
  font-family: Gilroy;
  font-weight: 400;
  font-size: 24px;
  line-height: 180%;
  text-align: justify;
  color: #303030;
  margin: 1.2em auto;
}

@media screen and (min-width: 676px) and (max-width: 1023px) {
  .header > div > p {
    width: 100%;
  }
}

@media (max-width: 676px) {
  .header {
    text-align: center;
    background: #f2f3f8;
    height: 50vh;
    margin: 0 0 2rem 0;
    padding: 0 2.5%;
  }

  .header > div > p {
    width: 50%;
  }

  .header > div > h4 {
    font-size: 32px;
    line-height: 48px;
    padding: 3em 0 1em 0;
    width: auto;
    margin: auto;
  }

  .header > div > p {
    font-size: 16px;
    line-height: 32px;
    width: auto;
  }

  .body {
    background: #ffffff;
    box-shadow: 2.30081px 11.5041px 23.0081px 1.15041px
      rgba(221, 227, 255, 0.45);
    border-radius: 20px;
    margin: 0 auto 3rem auto;
    padding: 1.6rem 0;
    position: relative;
    top: -80px;
    box-sizing: border-box;
    /* width: 20px; */
  }

  .bodyInner {
    width: 90%;
    margin: auto;
    position: relative;
  }

  .bodyInner > p {
    font-size: 14px;
    line-height: 28px;
  }

  .bodyInner > div:nth-child(2) > p {
    font-size: 16px;
    line-height: 32px;
  }
}

@font-face {
  font-family: "Gilroy";
  src: url("../../Fonts/Gilroy Font/Gilroy-Bold.woff");
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: "Gilroy";
  src: url("../../Fonts/Gilroy Font/Gilroy-Regular.woff");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "Gilroy";
  src: url("../../Fonts/Gilroy Font/Gilroy-SemiBold.woff");
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: "Gilroy";
  src: url("../../Fonts/Gilroy Font/Gilroy-Medium.woff");
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: "Metropolis";
  src: url("../../Fonts/Metropolis/Metropolis-SemiBold.otf");
  font-style: normal;
  font-weight: 600;
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  box-shadow: 0px 4px 70px rgba(85, 82, 81, 0.1);
  border-radius: 20px;
  width: 70%;
  margin: auto;
}

.innerContainer {
  display: flex;
  align-items: center;
}

.innerContainer > div {
  padding: 0 2.5rem;
  margin: 2rem 0;
  border-right: 1px solid #b2b3cf;
  text-align: center;
}

.innerContainer > div:last-child {
  border-right: none;
}

.innerContainer > div > p:nth-child(1) {
  font-family: Gilroy;
  font-size: 30px;
  line-height: 40px;
  color: #2855ae;
  margin: 0.5rem;
}

.innerContainer > div > p:nth-child(2) {
  font-family: Gilroy;
  font-weight: 500;
  font-size: 30px;
  line-height: 40px;
  color: rgba(105, 105, 132, 0.76);
  margin: 0.5rem;
}

@media (min-width: 767px) and (max-width: 1023px) {
  .container {
    width: 60%;
    margin: auto;
    border-radius: 6.8px;
  }

  .innerContainer > div {
    padding: 0 0.5rem;
    margin: 2rem 0;
    border-right: 1px solid #b2b3cf;
    width: 100%;
  }

  .innerContainer > div > p:nth-child(1) {
    font-size: 20.3333px;
    line-height: 14px;
  }

  .innerContainer > div > p:nth-child(2) {
    font-size: 16.3333px;
    line-height: 14px;
  }
}

@media (max-width: 767px) {
  .container {
    width: 80%;
    margin: auto;
    border-radius: 6.8px;
  }

  .innerContainer > div {
    padding: 0 0.5rem;
    margin: 0.5rem 0;
    border-right: 1px solid #b2b3cf;
    width: 100%;
  }

  .innerContainer > div > p:nth-child(1) {
    font-size: 10.3333px;
    line-height: 14px;
  }

  .innerContainer > div > p:nth-child(2) {
    font-size: 10.3333px;
    line-height: 14px;
  }
}

@font-face {
  font-family: "Gilroy";
  src: url("../../Fonts/Gilroy Font/Gilroy-Bold.woff");
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: "Gilroy";
  src: url("../../Fonts/Gilroy Font/Gilroy-Regular.woff");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "Gilroy";
  src: url("../../Fonts/Gilroy Font/Gilroy-SemiBold.woff");
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: "Gilroy";
  src: url("../../Fonts/Gilroy Font/Gilroy-Medium.woff");
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: "Metropolis";
  src: url("../../Fonts/Metropolis/Metropolis-SemiBold.otf");
  font-style: normal;
  font-weight: 600;
}

.header {
  text-align: center;
  background: #f2f3f8;
  height: 70vh;
}

.header > div > h4 {
  font-family: Gilroy;
  font-weight: 700;
  font-size: 54px;
  line-height: 74px;
  color: #1a1a1a;
  margin: 0;
  padding: 3em 0 1em 0;
  width: 872px;
  margin: auto;
}

.header > div > p {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 40px;
  text-align: center;
  color: #303030;
  width: 70%;
  margin: auto;
}

.body {
  background: #ffffff;
  box-shadow: 2.30081px 11.5041px 23.0081px 1.15041px rgba(221, 227, 255, 0.45);
  border-radius: 20px;
  width: 80%;
  margin: 0 auto 5rem auto;
  padding: 90px 0;
  position: relative;
  top: -100px;
}

.bodyInner {
  width: 76%;
  margin: auto;
  position: relative;
  top: 0;
}

.bodyInner > iframe {
  height: 663px;
  width: 100%;
  border-radius: 24px;
}

@media (min-width: 767px) and (max-width: 1023px) {
  .header {
    text-align: center;
    background: #f2f3f8;
    height: 30vh;
    padding: 0 2.5%;
  }

  .header > div > h4 {
    font-size: 32px;
    line-height: 48px;
    padding: 3em 0 1em 0;
    width: auto;
    margin: auto;
  }

  .header > div > p {
    font-size: 20px;
    line-height: 30px;
    width: 100%;
  }

  .bodyInner {
    width: 90%;
    margin: auto;
    position: relative;
  }
}

@media (max-width: 767px) {
  .header {
    text-align: center;
    background: #f2f3f8;
    height: 50vh;
    padding: 0 2.5%;
  }

  .header > div > h4 {
    font-size: 24px;
    line-height: 36px;
    padding: 3em 0 1em 0;
    width: auto;
    margin: auto;
  }

  .header > div > p {
    font-size: 14px;
    line-height: 21px;
    width: 100%;
  }

  .bodyInner {
    width: 90%;
    margin: auto;
    position: relative;
  }
}

@font-face {
  font-family: "Gilroy";
  src: url("../../Fonts/Gilroy Font/Gilroy-Bold.woff");
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: "Gilroy";
  src: url("../../Fonts/Gilroy Font/Gilroy-Regular.woff");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "Gilroy";
  src: url("../../Fonts/Gilroy Font/Gilroy-SemiBold.woff");
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: "Gilroy";
  src: url("../../Fonts/Gilroy Font/Gilroy-Medium.woff");
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: "Metropolis";
  src: url("../../Fonts/Metropolis/Metropolis-SemiBold.otf");
  font-style: normal;
  font-weight: 600;
}

.header {
  text-align: center;
  background: #f2f3f8;
  height: 70vh;
}

.header > div > h4 {
  font-family: Gilroy;
  font-weight: 700;
  font-size: 54px;
  line-height: 74px;
  color: #1a1a1a;
  margin: 0;
  padding: 3em 0 1em 0;
  width: 872px;
  margin: auto;
}

.body {
  background: #ffffff;
  box-shadow: 2.30081px 11.5041px 23.0081px 1.15041px rgba(221, 227, 255, 0.45);
  border-radius: 20px;
  width: 80%;
  margin: 0 auto 5rem auto;
  padding: 3.6rem 0;
  position: relative;
  top: -100px;
}

.bodyInner {
  width: 76%;
  margin: auto;
  position: relative;
  top: 0;
}

.bodyInner > h4 {
  font-family: Gilroy;
  font-weight: 600;
  font-size: 36px;
  line-height: 40px;
  color: #444f60;
}

.bodyInner > p {
  font-family: Gilroy;
  font-weight: 400;
  font-size: 24px;
  line-height: 40px;
  color: #303030;
}

.bodyInner > div:nth-child(2) > p {
  font-family: Gilroy;
  font-weight: 400;
  font-size: 24px;
  line-height: 180%;
  text-align: justify;
  color: #303030;
  margin: 1.2em auto;
}

.subscriptionDescription {
  display: flex;
  align-items: center;
  margin: 2rem 0 4rem 0;
}

.subscriptionDescription > div {
  width: 30%;
}

.subscriptionDescription > div > div {
  display: flex;
  align-items: center;
  gap: 18px;
}

.subscriptionDescription > div > div > p {
  font-family: Gilroy;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #303030;
}

.leftSection > div > i {
  color: #2855ae;
}

.rightSection > div > i {
  color: #2855ae;
}

.plan {
  border: 2px solid #d8dce9;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 2rem;
  margin-top: 2.5rem;
  transition: all 0.3s ease-in-out;
}

.planActive {
  border: 2px solid #2855ae;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 2rem;
  margin-top: 2.5rem;
  transition: all 0.3s ease-in-out;
}

.planLeftSection {
  width: 60%;
}

.planLeftSection > p:nth-child(1) {
  font-family: Gilroy;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #303030;
}

.planLeftSection > p:nth-child(2) {
  font-family: Gilroy;
  font-weight: 400;
  font-size: 16px;
  line-height: 14px;
  color: #999999;
}

.planRightSection {
  display: flex;
  align-items: center;
  justify-content: space-between;
  justify-self: flex-start;
}

.planRightSection > p {
  font-family: Gilroy;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #303030;
}

.planRightSection > div {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.planRightSection > div > input {
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  transition: all 0.3s ease-in-out;
}

.planRightSection > div > input[type="radio"]:after {
  height: 20px;
  width: 20px;
  border-radius: 15px;
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  background-color: #ffffff;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 2px solid #d8dce9;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.planRightSection > div > input[type="radio"]:checked::after {
  height: 20px;
  width: 20px;
  /* border-radius: 15px; */
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    linear-gradient(313.78deg, #2855ae -3.15%, #7292cf 100%);
  content: "";
  display: inline-block;
  visibility: visible;
  border: 2px solid #d8dce9;
  transition: all 0.3s ease-in-out;
}

@media (min-width: 767px) and (max-width: 1023px) {
  .header {
    text-align: center;
    background: #f2f3f8;
    height: 30vh;
  }

  .header > div > h4 {
    font-size: 32px;
    line-height: 48px;
    padding: 3em 0 1em 0;
    width: 80%;
    margin: auto;
  }

  .body {
    border-radius: 20px;
    /* margin: 0 auto 3rem auto; */
    margin: auto;
    padding: 1.6rem 0;
    top: 0px;
    /* width: 10%; */
  }

  .bodyInner {
    width: 90%;
    margin: auto;
    position: relative;
    top: 0;
  }

  .bodyInner > h4 {
    font-size: 18px;
    line-height: 140%;
  }

  .bodyInner > p {
    font-size: 14px;
    line-height: 28px;
  }

  .subscriptionDescription > div {
    width: 50%;
  }

  .subscriptionDescription > div > div {
    gap: 10px;
  }

  .subscriptionDescription > div > div > p {
    font-size: 12px;
    line-height: 16px;
  }

  .planLeftSection {
    width: 100%;
  }

  .planRightSection {
    width: 100%;
  }

  .planLeftSection > p:nth-child(1) {
    font-size: 16px;
    line-height: 24px;
  }

  .planLeftSection > p:nth-child(2) {
    font-size: 12px;
    line-height: 14px;
  }

  .plan,
  .planActive {
    padding: 0.5rem 1rem;
    margin-top: 2.5rem;
    transition: all 0.3s ease-in-out;
  }

  .planRightSection > p {
    font-size: 18px;
    line-height: 24px;
  }

  .planRightSection > div > input[type="radio"]:after {
    height: 16px;
    width: 16px;
  }

  .planRightSection > div > input[type="radio"]:checked::after {
    height: 16px;
    width: 16px;
  }
}

@media (max-width: 767px) {
  .header {
    text-align: center;
    background: #f2f3f8;
    height: 50vh;
    padding: 0 2.5%;
  }

  .header > div > h4 {
    font-size: 32px;
    line-height: 48px;
    padding: 3em 0 1em 0;
    width: auto;
    margin: auto;
  }

  .body {
    background: #ffffff;
    box-shadow: 2.30081px 11.5041px 23.0081px 1.15041px
      rgba(221, 227, 255, 0.45);
    border-radius: 20px;
    margin: 0 auto 3rem auto;
    padding: 1.6rem 0;
    position: relative;
    top: -80px;
    box-sizing: border-box;
    /* width: 20px; */
  }

  .bodyInner {
    width: 90%;
    margin: auto;
    position: relative;
  }

  .bodyInner > h4 {
    font-size: 18px;
    line-height: 140%;
  }

  .bodyInner > p {
    font-size: 14px;
    line-height: 28px;
  }

  .subscriptionDescription > div {
    width: 50%;
  }

  .subscriptionDescription > div > div {
    gap: 10px;
  }

  .subscriptionDescription > div > div > p {
    font-size: 12px;
    line-height: 16px;
  }

  .planLeftSection {
    width: 100%;
  }

  .planRightSection {
    width: 30%;
  }

  .planLeftSection > p:nth-child(1) {
    font-size: 16px;
    line-height: 24px;
  }

  .planLeftSection > p:nth-child(2) {
    font-size: 12px;
    line-height: 14px;
  }

  .plan,
  .planActive {
    padding: 0.5rem 1rem;
    margin-top: 2.5rem;
    transition: all 0.3s ease-in-out;
  }

  .planRightSection > p {
    font-size: 18px;
    line-height: 24px;
    width: 90%;
  }

  .planRightSection > div > input[type="radio"]:after {
    height: 16px;
    width: 16px;
  }

  .planRightSection > div > input[type="radio"]:checked::after {
    height: 16px;
    width: 16px;
  }
}

@font-face {
  font-family: "Gilroy";
  src: url("../../Fonts/Gilroy Font/Gilroy-Bold.woff");
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: "Gilroy";
  src: url("../../Fonts/Gilroy Font/Gilroy-Regular.woff");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "Gilroy";
  src: url("../../Fonts/Gilroy Font/Gilroy-SemiBold.woff");
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: "Gilroy";
  src: url("../../Fonts/Gilroy Font/Gilroy-Medium.woff");
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: "Avenir";
  src: url("../../Fonts/avenir_ff/AvenirLTStd-Black.otf");
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: "Metropolis";
  src: url("../../Fonts/Metropolis/Metropolis-SemiBold.otf");
  font-style: normal;
  font-weight: 600;
}

.container {
  width: 90%;
  margin: auto;
  height: 110vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.innerContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  /* border: 2px solid green; */
}

.innerContainer > div:nth-child(1) {
  width: 50%;
  text-align: center;
  /* border: 2px solid green; */
}

.innerContainer > div:nth-child(1) > iframe {
  height: 424px;
  width: 612px;
  border-radius: 24px;
}

.innerContainer > div:nth-child(2) {
  width: 45%;
  /* border: 2px solid green; */
}

.innerContainer > div:nth-child(2) > h4 {
  font-family: Gilroy;
  font-weight: 700;
  font-size: 30px;
  line-height: 40px;
  color: #2855ae;
  margin: 0 0 1em 0;
}

.innerContainer > div:nth-child(2) > p {
  font-family: Gilroy;
  font-weight: 400;
  font-size: 18px;
  line-height: 180%;
  letter-spacing: 0.03em;
  color: #303030;
}

.innerContainer > div:nth-child(2) > button {
  padding: 0.8rem 2rem;
  font-family: Gilroy;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.145454px;
  color: #ffffff;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    linear-gradient(313.78deg, #2855ae -3.15%, #7292cf 100%);
  border-radius: 40px;
  border: none;
  margin-top: 2rem;
}

@media (min-width: 767px) and (max-width: 1023px) {
  .container {
    height: auto;
    padding: 0 5%;
    margin-top: 3rem;
  }

  .innerContainer {
    flex-direction: column;
  }

  .innerContainer > div:nth-child(1) {
    width: 100%;
    order: 1;
    margin-top: 2rem;
  }

  .innerContainer > div:nth-child(1) > iframe {
    height: 502.1px;
    width: 100%;
    border-radius: 17.098039627075195px;
    border-radius: 24px;
  }

  .innerContainer > div:nth-child(2) {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .container {
    height: auto;
    padding: 0 5%;
    margin-top: 3rem;
  }

  .innerContainer {
    flex-direction: column;
  }

  .innerContainer > div:nth-child(1) {
    width: 100%;
    order: 1;
    margin-top: 2rem;
  }

  .innerContainer > div:nth-child(1) > iframe {
    height: 302.1px;
    width: 100%;
    border-radius: 17.098039627075195px;
    border-radius: 24px;
  }

  .innerContainer > div:nth-child(2) {
    width: 100%;
  }
}

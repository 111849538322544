@font-face {
  font-family: "Gilroy";
  src: url("../../Fonts/Gilroy Font/Gilroy-Bold.woff");
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: "Gilroy";
  src: url("../../Fonts/Gilroy Font/Gilroy-Regular.woff");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "Gilroy";
  src: url("../../Fonts/Gilroy Font/Gilroy-SemiBold.woff");
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: "Gilroy";
  src: url("../../Fonts/Gilroy Font/Gilroy-Medium.woff");
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: "Metropolis";
  src: url("../../Fonts/Metropolis/Metropolis-SemiBold.otf");
  font-style: normal;
  font-weight: 600;
}

.container {
  width: 90%;
  margin: auto;
}

.header {
  text-align: center;
  margin-bottom: 4rem;
}

.header > h4 {
  font-family: Gilroy;
  font-weight: 600;
  font-size: 36px;
  line-height: 40px;
  color: #1a1a1a;
}

.header > p {
  font-family: Gilroy;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 180%;
  text-align: center;
  color: #303030;
  width: 560px;
  margin: auto;
}

.featuresContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #f2f3f8;
  border-radius: 32px;
  margin-top: 3.5rem;
  height: 90vh;
  padding: 0 5%;
}

.featuresContainer > div:nth-child(1) {
  width: 50%;
}

.featuresContainer > div:nth-child(2) {
  width: 45%;
}

.features > .featuresContainer:nth-child(even) > div:nth-child(1) {
  order: 1;
  width: 50%;
}

.leftSection {
  width: 50%;
}

.leftSection > img {
  width: 100%;
  height: 100%;
}

.rightSection {
  width: 50%;
}

.rightSection > div:nth-child(1) {
  display: flex;
  align-items: center;
  gap: 8px;
}

.rightSection > div:nth-child(1) > span:nth-child(1) {
  display: block;
  width: 24px;
}

.rightSection > div:nth-child(1) > span:nth-child(1) > hr {
  border-top: 1px solid #1a1a1a;
  border-bottom: none;
  border-left: none;
  border-right: none;
}

.rightSection > div:nth-child(1) > span:nth-child(2) {
  font-family: Gilroy;
  font-weight: 400;
  font-size: 20px;
  line-height: 153.8%;
  color: #1a1a1a;
}

.rightSection > h4 {
  font-family: Gilroy;
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 48px;
  color: #1a1a1a;
  width: 436px;
  margin: 0.3em 0 0.7em 0;
}

.rightSection > p {
  font-family: Gilroy;
  font-weight: 400;
  font-size: 18px;
  line-height: 180%;
  letter-spacing: 0.03em;
  color: #303030;
  width: 469px;
}

.rightSection > button {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    linear-gradient(313.78deg, #2855ae -3.15%, #7292cf 100%);
  border-radius: 40px;
  padding: 0.8rem 2rem;
  font-family: Gilroy;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.145454px;
  border: none;
  color: #ffffff;
  cursor: pointer;
  margin-top: 2rem;
}

@media (min-width: 767px) and (max-width: 1023px) {
  .header > p {
    width: auto;
    font-size: 16px;
    line-height: 153.8%;
    width: auto;
  }

  .header > h4 {
    font-size: 30px;
    line-height: 153.8%;
  }

  .featuresContainer {
    flex-direction: column;
    padding: 2rem 2rem;
    height: auto;
    width: 100%;
    box-sizing: border-box;
  }

  .featuresContainer > div:nth-child(1),
  .featuresContainer > div:nth-child(2) {
    width: 100%;
  }

  .featuresContainer > div:nth-child(1) {
    order: 1;
    margin-top: 2rem;
  }

  .rightSection > div:nth-child(1) > span:nth-child(2) {
    font-size: 12px;
  }

  .rightSection > h4 {
    width: auto;
    font-size: 30px;
    margin: 0.5rem 0;
    line-height: 40px;
  }

  .rightSection > p {
    width: auto;
    font-size: 16px;
    line-height: 180%;
  }
  .features > .featuresContainer:nth-child(even) > div:nth-child(1) {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .header > p {
    width: auto;
    font-size: 16px;
    line-height: 153.8%;
    width: auto;
  }

  .header > h4 {
    font-size: 30px;
    line-height: 153.8%;
  }

  .featuresContainer {
    flex-direction: column;
    padding: 2rem 1rem;
    height: auto;
    width: 100%;
    box-sizing: border-box;
  }

  .featuresContainer > div:nth-child(1),
  .featuresContainer > div:nth-child(2) {
    width: 100%;
  }

  .featuresContainer > div:nth-child(1) {
    order: 1;
    margin-top: 2rem;
  }

  .rightSection > div:nth-child(1) > span:nth-child(2) {
    font-size: 12px;
  }

  .rightSection > h4 {
    width: auto;
    font-size: 30px;
    margin: 0.5rem 0;
    line-height: 40px;
  }

  .rightSection > p {
    width: auto;
    font-size: 16px;
    line-height: 180%;
  }

  .features > .featuresContainer:nth-child(even) > div:nth-child(1) {
    /* order: 0; */
    width: 100%;
  }
}

@font-face {
  font-family: "Gilroy";
  src: url("../../Fonts/Gilroy Font/Gilroy-Bold.woff");
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: "Gilroy";
  src: url("../../Fonts/Gilroy Font/Gilroy-Regular.woff");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "Gilroy";
  src: url("../../Fonts/Gilroy Font/Gilroy-SemiBold.woff");
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: "Gilroy";
  src: url("../../Fonts/Gilroy Font/Gilroy-Medium.woff");
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: "Metropolis";
  src: url("../../Fonts/Metropolis/Metropolis-SemiBold.otf");
  font-style: normal;
  font-weight: 600;
}

.container {
  width: 90%;
  margin: auto;
  display: flex;
  align-items: center;
  height: 100vh;
}

.leftSection {
  width: 50%;
}

.leftSection > h2 {
  font-family: "Gilroy";
  font-weight: 700;
  font-size: 54px;
  line-height: 74px;
  color: #1a1a1a;
  position: relative;
  margin: 0;
}

.leftSection > h2 > span {
  position: absolute;
  left: 0;
  top: 60px;
  display: flex;
  align-items: center;
}

.leftSection > h2 > span > img {
  margin: 0;
  display: flex;
  align-items: center;
}

.leftSection > p {
  font-family: Gilroy;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.05em;
  color: #303030;
  width: 80%;
  margin-bottom: 2rem;
}

.leftSection > div {
  display: flex;
  align-items: center;
  gap: 40px;
}

.leftSection > div > span {
  display: flex;
  align-items: center;
  font-family: Gilroy;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #303030;
  cursor: pointer;
}

.leftSection > div > span > span {
  /* border: 2px solid red; */
  margin-bottom: 0.5rem;
}

.leftSection > div > button {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    linear-gradient(313.78deg, #2855ae -3.15%, #7292cf 100%);
  border-radius: 40px;
  padding: 1rem 2.25rem;
  font-family: Gilroy;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.145454px;
  border: none;
  color: #ffffff;
  cursor: pointer;
}

.rightSection {
  height: 100%;
  width: 50%;
}

.rightSection > img {
  height: 100%;
  width: 100%;
}

@media (min-width: 767px) and (max-width: 1023px) {
  .container {
    flex-direction: column;
    justify-content: space-between;
    height: auto;
    gap: 0px;
    margin-bottom: 4rem;
  }

  .leftSection {
    width: 100%;
    height: auto;
    margin-bottom: 4rem;
  }

  .leftSection > h2 > span > img {
    margin: 0;
    display: flex;
    align-items: center;
    width: 3em;
  }

  .rightSection {
    width: 100%;
    height: auto;
  }

  .leftSection > div > button {
    margin-top: 2rem;
    padding: 0.8rem 2rem;
  }

  .leftSection > div > span {
    margin-top: 2rem;
    display: flex;
  }
}

@media (max-width: 767px) {
  .container {
    flex-direction: column;
    justify-content: space-between;
    height: auto;
    gap: 0px;
    margin-bottom: 4rem;
  }

  .leftSection {
    width: 100%;
    height: auto;
    margin-bottom: 4rem;
  }

  .leftSection > h2 {
    font-size: 35px;
    line-height: 56px;
  }

  .leftSection > h2 > span {
    top: 45px;
  }

  .leftSection > h2 > span > img {
    margin: 0;
    display: flex;
    align-items: center;
    width: 3em;
  }

  .leftSection > p {
    width: 100%;
    font-size: 18px;
    line-height: 24px;
  }

  .rightSection {
    width: 100%;
    height: auto;
  }

  .leftSection > div > button {
    margin-top: 2rem;
    padding: 0.5rem 2rem;
  }

  .leftSection > div > span {
    margin-top: 2rem;
    display: flex;
  }
}

@font-face {
  font-family: "Gilroy";
  src: url("../../Fonts/Gilroy Font/Gilroy-Bold.woff");
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: "Gilroy";
  src: url("../../Fonts/Gilroy Font/Gilroy-Regular.woff");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "Gilroy";
  src: url("../../Fonts/Gilroy Font/Gilroy-SemiBold.woff");
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: "Gilroy";
  src: url("../../Fonts/Gilroy Font/Gilroy-Medium.woff");
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: "Avenir";
  src: url("../../Fonts/avenir_ff/AvenirLTStd-Black.otf");
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: "Metropolis";
  src: url("../../Fonts/Metropolis/Metropolis-SemiBold.otf");
  font-style: normal;
  font-weight: 600;
}

.container {
  background: #294b8f;
}

.innerContainer {
  width: 90%;
  margin: auto;
  display: flex;
  align-items: flex-start;
  padding-top: 2rem;
}

.innerContainer > div:nth-child(1) {
  width: 40%;
}

.socialSection {
  display: flex;
  align-items: center;
  gap: 16px;
  font-size: 2px;
  margin: 2rem 0;
}

.innerContainer > div:nth-child(2),
.innerContainer > div:nth-child(3) {
  width: 15%;
}

.innerContainer > div:nth-child(4) {
  width: 30%;
}

.innerContainer > div > h4,
h3 {
  font-family: Gilroy;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  color: #ffffff;
}

.innerContainer > div > a,
p {
  display: block;
  font-family: Gilroy;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #d9dbe1;
  text-decoration: none;
  margin: 0.8rem 0;
}

.innerContainer > div:nth-child(4) > div {
  height: 40px;
  width: 255px;
  position: relative;
  margin-top: 1rem;
}

.innerContainer > div:nth-child(4) > div > input {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background: #ffffff;
  background: #546fa5;
  border-radius: 8px;
  outline: none;
  font-family: Gilroy;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #d9dbe1;
  padding: 0 2rem 0 1rem;
  border: none;
}

.innerContainer > div:nth-child(4) > div > input::placeholder {
  font-family: Gilroy;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #d9dbe1;
  display: absolute;
}

.innerContainer > div:nth-child(4) > div > img {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  right: 13px;
  cursor: pointer;
}

.copyrightsection {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3rem 0;
  width: 90%;
  margin: auto;
  border-top: 2px solid #335494;
  margin-top: 2rem;
}

@media (min-width: 767px) and (max-width: 1023px) {
  .innerContainer {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .innerContainer > div:nth-child(1),
  .innerContainer > div:nth-child(2),
  .innerContainer > div:nth-child(3),
  .innerContainer > div:nth-child(4) {
    width: 45%;
  }
}

@media (max-width: 767px) {
  .innerContainer {
    flex-direction: column;
  }

  .innerContainer > div:nth-child(1),
  .innerContainer > div:nth-child(2),
  .innerContainer > div:nth-child(3),
  .innerContainer > div:nth-child(4) {
    width: 100%;
  }
}

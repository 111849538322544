@font-face {
  font-family: "Gilroy";
  src: url("../../Fonts/Gilroy Font/Gilroy-Bold.woff");
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: "Gilroy";
  src: url("../../Fonts/Gilroy Font/Gilroy-Regular.woff");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "Gilroy";
  src: url("../../Fonts/Gilroy Font/Gilroy-SemiBold.woff");
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: "Gilroy";
  src: url("../../Fonts/Gilroy Font/Gilroy-Medium.woff");
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: "Metropolis";
  src: url("../../Fonts/Metropolis/Metropolis-SemiBold.otf");
  font-style: normal;
  font-weight: 600;
}

@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.header {
  text-align: center;
  background: #f2f3f8;
  height: 70vh;
}

.header > div > h4 {
  font-family: Gilroy;
  font-weight: 700;
  font-size: 54px;
  line-height: 74px;
  color: #1a1a1a;
  margin: 0;
  padding: 3em 0 1em 0;
  width: 872px;
  margin: auto;
}

.body {
  background: #ffffff;
  background: #ffffff;
  box-shadow: 2.30081px 11.5041px 23.0081px 1.15041px rgba(221, 227, 255, 0.45);
  border-radius: 20px;
  width: 80%;
  margin: 0 auto 0rem auto;
  padding: 3.6rem 0;
  position: relative;
  top: -100px;
}

.bodyInner {
  width: 90%;
  margin: auto;
  position: relative;
  top: 0;
}

.bodyInner > h4 {
  font-family: Gilroy;
  font-weight: 600;
  font-size: 36px;
  line-height: 40px;
  color: #444f60;
}

.bodyInner > p {
  font-family: Gilroy;
  font-weight: 400;
  font-size: 24px;
  line-height: 40px;
  color: #303030;
}

.bodyInner > div:nth-child(2) > p {
  font-family: Gilroy;
  font-weight: 400;
  font-size: 24px;
  line-height: 180%;
  text-align: justify;
  color: #303030;
  margin: 1.2em auto;
}

.faq {
  background: #ffffff;
  box-shadow: 0px 4px 9px 2px rgba(206, 211, 235, 0.35);
  border-radius: 10px;
  padding: 1rem 3rem;
  margin: 0.8rem 0;
}

.faqHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  user-select: none;
  cursor: pointer;
  gap: 16px;
}

.faqHeader > h4 {
  font-family: Gilroy;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #444f60;
}

.faqDetail {
  font-family: Gilroy;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  color: #999999;
  transition: all 0.3s ease-in-out;
  overflow-y: auto;
  margin-top: 0.5rem;
}

.faqDetail::-webkit-scrollbar {
  width: 3px;
}

.faqDetail::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.faqDetail::-webkit-scrollbar-thumb {
  background: #999999;
}

.faqDetail::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.faqDetail > span {
  animation: appear 0.4s ease-in-out;
}

.getInTouch {
  margin-bottom: 7rem;
}

.getInTouchInner {
  width: 50%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #f2f3f8;
  border-radius: 12px;
  padding: 1.5rem 2rem;
}

.getInTouchInner > div:nth-child(1) > h4 {
  font-family: Gilroy;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #444f60;
  margin: 0 0 0.5em 0;
}

.getInTouchInner > div:nth-child(1) > p {
  font-family: Gilroy;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #444f60;
  margin: 0;
}

.getInTouchInner > button {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    linear-gradient(313.78deg, #2855ae -3.15%, #7292cf 100%);
  border-radius: 40px;
  padding: 0.5rem 1.3rem;
  font-family: Gilroy;
  font-weight: 600;
  font-size: 11.767px;
  line-height: 18px;
  color: #ffffff;
  border: none;
}

@media (max-width: 767px) {
  .header {
    text-align: center;
    background: #f2f3f8;
    height: 50vh;
    margin: 0 0 2rem 0;
    padding: 0 2.5%;
  }

  .header > div > h4 {
    font-size: 32px;
    line-height: 48px;
    padding: 3em 0 1em 0;
    width: auto;
    margin: auto;
  }

  .header > div > p {
    font-size: 16px;
    line-height: 32px;
    width: auto;
  }

  .body {
    background: #ffffff;
    box-shadow: 2.30081px 11.5041px 23.0081px 1.15041px
      rgba(221, 227, 255, 0.45);
    border-radius: 20px;
    margin: 0 auto 0 auto;
    padding: 1.6rem 0;
    position: relative;
    top: -80px;
    box-sizing: border-box;
    /* width: 20px; */
  }

  .bodyInner {
    width: 90%;
    margin: auto;
    position: relative;
  }

  .faq {
    background: #ffffff;
    box-shadow: 0px 4px 9px 2px rgba(206, 211, 235, 0.35);
    border-radius: 10px;
    padding: 0.5rem 1rem;
    margin: 0.8rem 0;
  }

  .faqHeader > h4 {
    font-size: 16px;
    line-height: 1.5em;
  }

  .faqDetail {
    font-size: 14px;
    line-height: 1.5em;
  }

  .getInTouchInner {
    width: 90%;
    margin: auto;
    padding: 1rem 1rem;
    gap: 16px;
  }

  .getInTouchInner > div:nth-child(1) > h4 {
    font-size: 12px;
    line-height: 16px;
  }

  .getInTouchInner > div:nth-child(1) > p {
    font-size: 12px;
    line-height: 16px;
  }

  .getInTouchInner > button {
    padding: 0.5em 1em;
    font-size: 9.767px;
    line-height: 14px;
    width: 40%;
    border-radius: 18px;
  }

  .getInTouch {
    margin-bottom: 4rem;
  }
}

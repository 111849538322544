.container {
  /* height: 220vh; */
  /* display: flex;
  justify-content: flex-end;
  flex-direction: column; */
  /* margin: 20rem; */
  width: 100%;
  margin: auto;
  padding: 5rem 0;
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.header > h4 {
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 40px;
  color: #1a1a1a;
}

.header > p {
  width: 560px;
  /* height: 72px; */
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 180%;
  color: #303030;
  text-align: center;
}

.videoSection {
  background: #f2f3f8;
  border-radius: 32px;
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
  padding: 4rem 0;
  margin: 3rem auto 2rem auto;
}

.videoCard {
  height: 414.5px;
  width: 332.5px;
  border-radius: 22.5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-sizing: border-box;
  background: #ffffff;
}

.pictureSection {
  height: 50%;
  width: 100%;
  padding: 18px;
}

.pictureSection > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.textSection {
  height: 50%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.textUpperSection {
  height: 75%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0 18px;
}

.tutorDescription {
  font-style: normal;
  font-weight: 400;
  font-size: 15.7708px;
  line-height: 18px;
  color: #444f60;
}

.videoTopic {
  font-style: normal;
  font-weight: 600;
  font-size: 22.5297px;
  line-height: 27px;
  color: #444f60;
  margin: 0.5em 0;
}

.videoDescription {
  font-style: normal;
  font-weight: 400;
  font-size: 15.7708px;
  line-height: 23px;
  color: #999999;
}

.textLowerSection {
  height: 25%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-style: normal;
  font-weight: 400;
  font-size: 15.7708px;
  line-height: 18px;
  color: #2855ae;
  border-top: 1px solid #e7e9eb;
  padding: 0 18px;
}

.textLowerSection > span:nth-child(2) {
  height: 34px;
  width: 34px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    linear-gradient(313.78deg, #2855ae -3.15%, #7292cf 100%);
  color: #ffffff;
  box-sizing: border-box;
  font-size: 0.9rem;
  cursor: pointer;
}

@media screen and (max-width: 767px) {
  .container {
    height: auto;
    flex-direction: column;
    margin-top: 4rem;
    width: 90%;
  }

  .videoSection {
    gap: 20px;
    flex-direction: column;
    padding: 2rem 1rem;
    height: auto;
    width: 100%;
    box-sizing: border-box;
  }

  .videoCard {
    width: 100%;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 2rem;
  }

  .header > p {
    width: auto;
    font-size: 16px;
    line-height: 153.8%;
    width: auto;
  }

  .header > h4 {
    font-size: 30px;
    line-height: 153.8%;
  }
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .container {
    height: auto;
    margin-top: 4rem;
    width: 90%;
  }

  .videoSection {
    flex-direction: column;
    gap: 20px;
    flex-direction: column;
    padding: 2rem 1rem;
    height: auto;
    width: 100%;
    box-sizing: border-box;
  }

  .videoCard {
    width: 100%;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 2rem;
  }

  .header > p {
    width: auto;
    font-size: 16px;
    line-height: 153.8%;
    width: auto;
  }

  .header > h4 {
    font-size: 30px;
    line-height: 153.8%;
  }
}

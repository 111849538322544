@font-face {
  font-family: "Gilroy";
  src: url("../../Fonts/Gilroy Font/Gilroy-Bold.woff");
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: "Gilroy";
  src: url("../../Fonts/Gilroy Font/Gilroy-Regular.woff");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "Gilroy";
  src: url("../../Fonts/Gilroy Font/Gilroy-SemiBold.woff");
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: "Gilroy";
  src: url("../../Fonts/Gilroy Font/Gilroy-Medium.woff");
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: "Metropolis";
  src: url("../../Fonts/Metropolis/Metropolis-SemiBold.otf");
  font-style: normal;
  font-weight: 600;
}

.container {
  /* border: 2px solid green; */
}

.header {
  /* border: 2px solid green; */
  font-family: Gilroy;
  font-weight: 700;
  font-size: 48px;
  line-height: 74px;
  color: #1a1a1a;
  text-align: center;
}

.boardOfAdvisory {
  display: flex;
  align-items: center;
  width: 70%;
  margin: 4rem auto;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 30px;
}

.boardOfAdvisory > div {
  position: relative;
  margin-bottom: 4rem;
  border-radius: 20px;
  height: 414px;
  width: 407px;
  border-radius: 20px;
  box-sizing: border-box;
}

.boardOfAdvisory > div > img {
  height: 100%;
  width: 100%;
  border-radius: 20px;
  object-fit: cover;
}

/* .advisor */
.boardOfAdvisory > div > div {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 50%;
  background: linear-gradient(0.58deg, #000000 0.5%, rgba(0, 0, 0, 0) 90.53%);
  opacity: 0.8;
  border-radius: 0px 0px 20px 20px;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  padding-left: 2rem;
}

.advisor > div > p:nth-child(1) {
  font-family: Gilroy;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #ffffff;
  margin: 0;
}

.advisor > div > p:nth-child(2) {
  font-family: Gilroy;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #d4d4d8;
  margin: 0 0 24px 0;
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .boardOfAdvisory {
    flex-direction: column;
    flex-wrap: nowrap;
    width: 50%;
  }

  .advisor {
    height: 314px;
    width: 307px;
  }
}

@media screen and (max-width: 767px) {
  .header {
    font-size: 32px;
    line-height: 48px;
  }

  .boardOfAdvisory {
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    width: 30%;
  }

  .boardOfAdvisory > div {
    height: 314px;
    width: 307px;
  }
}

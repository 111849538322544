@font-face {
  font-family: "Gilroy";
  src: url("../../Fonts/Gilroy Font/Gilroy-Bold.woff");
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: "Gilroy";
  src: url("../../Fonts/Gilroy Font/Gilroy-Regular.woff");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "Gilroy";
  src: url("../../Fonts/Gilroy Font/Gilroy-SemiBold.woff");
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: "Gilroy";
  src: url("../../Fonts/Gilroy Font/Gilroy-Medium.woff");
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: "Metropolis";
  src: url("../../Fonts/Metropolis/Metropolis-SemiBold.otf");
  font-style: normal;
  font-weight: 600;
}

.container {
  /* width: 90%;
  margin: auto; */
  padding: 0 5%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 10vh;
  background: #f2f3f8;
  position: relative;
}

.rightSection {
  display: flex;
  align-items: center;
  gap: 56px;
}

.rightSection > div {
  position: relative;
}

.rightSection > div > a {
  font-family: Gilroy;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #2855ae;
  text-decoration: none;
  transition: all 0.1s ease-in;
  display: block;
  position: relative;
  z-index: 2;
}

.rightSection > .activeLink > a {
  font-family: Gilroy;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #2855ae;
  text-decoration: none;
  transition: all 0.1s ease-in;
  display: block;
  position: relative;
  z-index: 2;
}

.rightSection > div > span {
  border-bottom: 2px solid #2855ae;
  position: absolute;
  top: 15px;
  bottom: 0;
  left: 0;
  right: 0;
  width: 0%;
}

.rightSection > .activeLink > span {
  border-bottom: 2px solid #2855ae;
  position: absolute;
  top: 15px;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
}

.rightSection > div:hover > span {
  width: 100%;
  transition: all 0.3s ease-in-out;
  border-bottom: 2px solid #7292cf;
}

.rightSection > .activeLink:hover > span {
  width: 100%;
  transition: all 0.3s ease-in-out;
  border-bottom: 2px solid #7292cf;
}

.rightSection > button {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    linear-gradient(313.78deg, #2855ae -3.15%, #7292cf 100%);
  border-radius: 40px;
  padding: 0.5rem 2rem;
  font-family: Gilroy;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.145454px;
  border: none;
  color: #ffffff;
}

.mobileRightSection {
  display: none;
}

.sideNav {
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1;
  overflow: hidden;
  width: 0px;
  transition: 0.3s;
  background-color: transparent;
  margin: auto;
  display: none;
}

.sidenavInner {
  position: absolute;
  width: 100vw;
  height: 100%;
  display: flex;
}

.sidenavInner > div:nth-child(1) {
  width: 20%;
  background: rgba(0, 0, 0, 0.4);
}

.sidenavInner > div:nth-child(1)::before {
  content: "";
}

.sidenavInner > div:nth-child(2) {
  padding: 2rem 2.5%;
  width: 80%;
  background-color: #ffffff;
}

.sidenavInner > div:nth-child(2) > div:nth-child(1) {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem 0 0;
}

.sideNavLinks {
  margin-top: 2rem;
}

.sideNavLinks > .inactiveLink {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1em 1rem;
  border-bottom: 2px solid #f2f3f8;
}

.sideNavLinks > .inactiveLink > a {
  font-family: Gilroy;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #444f60;
  text-decoration: none;
}

.sideNavLinks > .inactiveLink > span {
  color: #999999;
}

.sideNavLinks > .activeLink {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1em 1rem;
  border-bottom: 2px solid #f2f3f8;
}

.sideNavLinks > .activeLink > a {
  font-family: Gilroy;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #444f60;
  text-decoration: none;
}

.sideNavLinks > .activeLink > span {
  color: #999999;
}

@media (min-width: 767px) and (max-width: 1023px) {
  .rightSection {
    display: none;
  }

  .mobileRightSection {
    display: block;
  }

  .sideNav {
    display: block;
  }
}

@media (max-width: 767px) {
  .rightSection {
    display: none;
  }

  .leftSection {
    height: 53px;
    width: 135px;
  }

  .leftSection > img {
    height: 100%;
    width: 100%;
  }

  .mobileRightSection {
    display: block;
  }

  .sideNav {
    display: block;
  }
}

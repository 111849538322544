.container {
  width: 100%;
  height: 100%;
  position: relative;
}

.container > iframe {
  width: 100%;
  height: 100%;
}

.container > i {
  position: absolute;
  font-size: 1rem;
  display: block;
  top: 0px;
  right: 0px;
  z-index: 10;
  cursor: pointer;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

.container > i:hover {
  background: #999999;
}

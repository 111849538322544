@font-face {
  font-family: "Gilroy";
  src: url("../../Fonts/Gilroy Font/Gilroy-Bold.woff");
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: "Gilroy";
  src: url("../../Fonts/Gilroy Font/Gilroy-Regular.woff");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "Gilroy";
  src: url("../../Fonts/Gilroy Font/Gilroy-SemiBold.woff");
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: "Gilroy";
  src: url("../../Fonts/Gilroy Font/Gilroy-Medium.woff");
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: "Avenir";
  src: url("../../Fonts/avenir_ff/AvenirLTStd-Black.otf");
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: "Metropolis";
  src: url("../../Fonts/Metropolis/Metropolis-SemiBold.otf");
  font-style: normal;
  font-weight: 600;
}

.container {
  background: #f2f3f8;
  width: 90%;
  margin: 9em auto;
  border-radius: 32px;
}

.subContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  margin: auto;
  font-family: Gilroy;
}

.textSection {
  width: 50%;
  margin: 5em 0em;
}

.textSection h1 {
  /* border: 1px solid black; */
  width: 80%;
  font-weight: 600;
  line-height: 146.3%;
  margin: 0;
  font-size: 40px;
}

.ysp {
  color: #2855ae;
}

.textSection p {
  /* border: 1px solid black; */
  width: 75%;
  line-height: 24px;
  line-height: 1.5rem;
  font-weight: 400;
  color: #000000;
}

.comingSoon {
  display: block;
  /* border: 1px solid green; */
  color: #2855ae;
  font-size: 20px;
  margin: 1.5em 0em;
  font-weight: 500;
}

.imageSection {
  width: 50%;
  text-align: right;
}

.imageSection img {
  /* border: 1px solid black; */
  width: 100%;
  height: 100%;
  position: relative;
  right: 20px;
  bottom: 45px;
  margin-right: auto;
}

.subscribeSection {
  height: 56px;
  position: relative;
}

.subscribeSection > input {
  height: 100%;
  border-radius: 60px;
  width: 100%;
  box-sizing: border-box;
  padding: 0 2rem;
  border: none;
  outline: none;
  font-family: Gilroy;
  font-weight: 400;
  font-size: 14px;
  line-height: 168%;
  letter-spacing: 0.02em;
}

.subscribeSection > input::placeholder {
  font-family: Gilroy;
  font-weight: 400;
  font-size: 14px;
  line-height: 168%;
  letter-spacing: 0.02em;
  color: #b0b0b0;
}

.subscribeSection > button {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  height: calc(100% - 16px);
  right: 8px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    linear-gradient(313.78deg, #2855ae -3.15%, #7292cf 100%);
  border-radius: 40px;
  padding: 0.5rem 1.5rem;
  font-family: Gilroy;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.145454px;
  color: #ffffff;
  border: none;
}

@media (min-width: 767px) and (max-width: 1023px) {
  .container {
    margin: 2em auto;
    text-align: center;
  }

  .subContainer {
    flex-direction: column;
    width: 90%;
  }

  .textSection {
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .textSection > h1 {
    font-size: 30px;
    line-height: 146.3%;
  }

  .textSection > p {
    font-size: 16px;
    line-height: 162.4%;
    width: auto;
  }

  .textSection > span {
    font-size: 20px;
    line-height: 162.4%;
  }

  .imageSection {
    width: 100%;
  }

  .imageSection > img {
    width: 100%;
    width: 100%;
    height: 100%;
    position: relative;
    right: 0px;
    bottom: 25px;
    margin-right: auto;
  }

  .subscribeSection > input {
    padding: 0 1rem;
  }
}

@media (max-width: 767px) {
  .container {
    margin: 2em auto;
    text-align: center;
  }

  .subContainer {
    flex-direction: column;
    width: 90%;
  }

  .textSection {
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .textSection > h1 {
    font-size: 30px;
    line-height: 146.3%;
  }

  .textSection > p {
    font-size: 16px;
    line-height: 162.4%;
    width: auto;
  }

  .textSection > span {
    font-size: 20px;
    line-height: 162.4%;
  }

  .imageSection {
    width: 100%;
  }

  .imageSection > img {
    width: 100%;
    width: 100%;
    height: 100%;
    position: relative;
    right: 0px;
    bottom: 25px;
    margin-right: auto;
  }

  .subscribeSection > input {
    padding: 0 1rem;
  }
}

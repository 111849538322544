.container {
  margin: 10rem auto 10rem auto;
  background: #f2f3f8;
  border-radius: 32px;
  width: 90%;
  padding: 2.5rem;
  font-family: Gilroy;
  font-weight: 400;
  font-size: 20px;
  line-height: 160%;
  text-align: center;
  color: #303030;
}

.imageSection {
  width: 60%;
  margin: 2rem auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media screen and (max-width: 767px) {
  .imageSection {
    flex-direction: column;
    gap: 3rem;
  }
}

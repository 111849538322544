.container {
  position: relative;
}

.heroSection {
  background: #f2f3f8;
  position: relative;
}

.header {
  /* position: sticky;
  top: 0; */
}

.statistic {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: -70px;
}

@media (max-width: 767px) {
  .statistic {
    bottom: -40px;
  }
}
